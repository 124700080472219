import React from 'react';
import { useConnect } from 'utils/framework';
import { getT } from 'utils/framework/intl';
import { LoansViewModel, loansStatusOptions } from './LoansViewModel';
import { Loan } from './Loan';
import { RequestPagination } from 'modules/ui-components/RequestPagination';
import { MultiSelect, Option } from 'modules/ui-components/MultiSelect';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const LoansSectionComponent = ({ className }: Props) => {
  const vm = useConnect(LoansViewModel);
  const _t = getT();

  if (vm.state.loanList.results?.length > 0 || vm.state.loanFiltersApplied) {
    return (
      <div className={className}>
        <div className="header">
          <h1 className="page__header">{_t('loans.activeLoans')}</h1>
          <MultiSelect
            options={loansStatusOptions}
            placeholder="All"
            submitEvent={(options: Array<Option>) => vm.applyLoansFilters(options)}
          />
        </div>
        {vm.state.loanList.results.map(loan => (
          <Loan key={loan.loanId} loan={loan} isBorrower={vm.state.isBorrower} />
        ))}
        <RequestPagination
          isLoading={vm.state.loanListIsLoading}
          hasNextPage={vm.state.loanListHasNextPage}
          hasPreviousPage={vm.state.loanList.page > 0}
          previous={vm.previousLoansPage}
          next={vm.nextLoansPage}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <h1 className="page__header">{_t('loans.activeLoans')}</h1>
      <div>
        {vm.state.loanListIsLoading ? (
          <p>{_t('uiComponents.loading')}</p>
        ) : (
          _t('loans.noActiveLoans')
        )}
      </div>
    </div>
  );
};

export const LoansSection = styled(LoansSectionComponent)`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
