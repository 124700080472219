import { ApplicationAsset } from './Application';

export enum InstallmentState {
  PAID = 'PAID',
  PENDING = 'PENDING',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  LATE = 'LATE',
}

export interface Loan {
  assetCost: number;
  assetType: string;
  bic: string;
  commissionAmount: number;
  currency: string;
  customer: string;
  dateOpened: string;
  daysInArrears: number;
  depositAmount: number;
  docFees: number;
  etlLogId: number;
  feesBalance: number;
  feesDue: number;
  feesPaid: number;
  iban: string;
  interestBalance: number;
  interestDue: number;
  interestPaid: number;
  interestRate: number;
  lastUpdate: string;
  loanAmount: number;
  loanId: string;
  loanName: string;
  paymentPreference: string;
  penaltyBalance: number;
  penaltyDue: number;
  penaltyPaid: number;
  principleBalance: number;
  principleDue: number;
  principlePaid: number;
  purchaseFees: number;
  settlementAmount: number;
  state: string;
  thirdPartyFees: number;
  tradeInAmount: number;
  upfrontAmount: number;
  vatAmount: number;
  vatCode: number;
  vatPercent: number;
  vrtAmount: number;
  totalBalance: number;
  nextPaymentAmount: number;
  balance: number;
  assets?: ApplicationAsset[];
  term?: number;
  transactions?: PaginatedResponse<Transactions>;
}

export enum PhoneNumberPrefixes {
  Ireland = '+353',
  England = '+44',
}

export interface PaginatedResponse<T> {
  page: number;
  moreRecords: boolean;
  results: Array<T>;
}

export interface Installment {
  loanId: string;
  installmentId: number;
  etlLogId: string;
  lastUpdate: string;
  installemntState: string;
  installmentDueDate: string;
  installmentCurrency: string;
  interestedAmountExpected: number;
  interestedAmountPaid: number;
  interestedAmountDue: number;
  interestTaxDue: number;
  feeTaxExpected: number;
  feeTaxPaid: number;
  feeTaxDue: number;
  penaltyAmountExpected: number;
  penaltyAmountPaid: number;
  penaltyAmountDue: number;
  penaltyTaxDue: number;
  principleAmountExpected: number;
  principleAmountPaid: number;
  principleAmountDue: number;
}

export interface LoanSchedule {
  installments: Installment[];
}

export interface CamelisedLoanScheduleResponse {
  limit: number;
  offset: number;
  results: Installment[];
  total: number;
  hasMore: boolean;
}

export interface LoanScheduleResponse {
  data: {
    has_more: boolean;
    limit: number;
    offset: number;
    results: Installment[];
    total: number;
  };
}

export interface LoansResponse {
  data: {
    results: Loan[];
    limit: number;
    offset: number;
    total: number;
    has_more: boolean;
  };
}

export interface CamelisedLoansResponse {
  results: Loan[];
  limit: number;
  offset: number;
  total: number;
  hasMore: boolean;
}

export interface Transactions {
  loanId: string;
  bookingDate: string;
  amount: number;
}

export interface LoanTransactionResponse {
  data: {
    has_more: boolean;
    limit: number;
    offset: number;
    results: Transactions[];
    total: number;
  };
}

export const LeaseProducts = [
  'BL-Business Lease',
  'SBL-Sale and Lease Back',
  'ELS-EELS Lease',
  'GBL-CGS Business Lease',
  'SLV-Sale & Lease Back LTV',
  'SBL-Sale and Lease Back GBP',
  'BL-Business Lease GBP',
  'UBL-UCGS Business Lease',
];

export const HPProducts = [
  'SHP-Sale and Hire Back GBP',
  'MHP-Motor Hire Purchase',
  'SHV-Sale & HP Back LTV',
  'GHP-CGS Hire Purchase',
  'SHV-Sale & HP Back LTV GBP',
  'BHP-Business Hire Purchase',
  'BHP-Business Hire Purchase GBP',
  'UHP-UCGS Hire Purchase',
  'SHP-Sale and Hire Back',
];
