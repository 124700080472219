import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { useConnect } from 'utils/framework';
import { _t, Currency } from 'utils/string';
import { Table } from 'modules/ui-components/table/Table';
import { RequestPagination } from 'modules/ui-components/RequestPagination';
import { LoanTransactionsViewModel } from './LoanTransactionViewModel';

interface Props {
  className?: string;
  loanId: string;
  currency: Currency;
}

const LoansTransactionHistoryContainer = ({ className, loanId, currency }: Props) => {
  const vm = useConnect(LoanTransactionsViewModel);

  useEffect(() => {
    vm.getTransactions(loanId);
  }, [loanId, vm]);

  return (
    <div className={className}>
      <Table
        tableHead={vm.state.tableHead}
        isLoading={vm.getIsLoading(loanId)}
        tableBody={vm.getTableBody(loanId, currency)}
        noContentNode={_t('loans.noTransactions')}
        renderColumns={{}}
      />
      <RequestPagination
        className="pagination"
        isLoading={vm.getIsLoading(loanId)}
        hasNextPage={vm.hasTransactionsNextPage(loanId)}
        hasPreviousPage={vm.getCurrentPage(loanId) >= 1}
        previous={() => {
          vm.previousTransactionPage(loanId);
        }}
        next={() => {
          vm.nextTransactionPage(loanId);
        }}
      />
    </div>
  );
};

export const LoanTransactionsHistory = styled(LoansTransactionHistoryContainer)`
  display: grid;
  padding: 1rem;
  background-color: var(--primary-dark-blue-25);
  border-top: var(--border-default);
  gap: 1rem;
  font-size: 0.875rem;

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    padding: 1.5rem;
  }
`;
