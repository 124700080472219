import { RequestPagination } from 'modules/ui-components/RequestPagination';
import React from 'react';
import { useConnect } from 'utils/framework';
import { getT } from 'utils/framework/intl';
import { Application } from './Application';
import { ApplicationsViewModel, applicationStatusOptions } from './ApplicationsViewModel';
import { MultiSelect, Option as MultiSelectOption } from 'modules/ui-components/MultiSelect';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

export const ApplicationsSectionComponent = ({ className }: Props) => {
  const vm = useConnect(ApplicationsViewModel);
  const _t = getT();

  if (vm.state.applicationList.results?.length > 0 || vm.state.areApplicationFiltersApplied) {
    return (
      <div className={className}>
        <div className="header">
          <h1 className="page__header">{_t('loans.applications')}</h1>
          <MultiSelect
            options={applicationStatusOptions}
            placeholder="All"
            submitEvent={(options: Array<MultiSelectOption>) => vm.applyApplicationFilters(options)}
          />
        </div>
        {vm.state.applicationList.results.map(application => (
          <Application key={application.id} application={application} vm={vm} />
        ))}
        <RequestPagination
          isLoading={vm.state.applicationListIsLoading}
          hasNextPage={vm.state.applicationListHasNextPage}
          hasPreviousPage={vm.state.applicationList.page > 1}
          previous={vm.previousApplicationsPage}
          next={vm.nextApplicationsPage}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="header">
        <h1 className="page__header">{_t('loans.applications')}</h1>
        {vm.state.zohoId && vm.state.areApplicationFiltersApplied && (
          <MultiSelect
            options={applicationStatusOptions}
            placeholder="All"
            submitEvent={(options: Array<MultiSelectOption>) => vm.applyApplicationFilters(options)}
          />
        )}
      </div>
      <p>
        {vm.state.applicationListIsLoading ? _t('uiComponents.loading') : _t('loans.noApplication')}
      </p>
    </div>
  );
};

export const ApplicationsSection = styled(ApplicationsSectionComponent)`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
