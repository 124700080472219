import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/Application';
import { Transactions } from 'models/Loan';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { container } from 'tsyringe';
import { State } from 'utils/store';
import { notifyError } from 'modules/notification/store';
import { getT } from 'utils/framework/intl';
import { AccountsService } from 'services/AccountsService';
import { captureException } from 'utils/reporting';

export interface LoanTransaction {
  transactions: PaginatedResponse<Transactions>;
  isLoading: boolean;
}

export interface LoansTransactions {
  [loanId: string]: LoanTransaction;
}

export interface LoanTransactionState {
  transactions: LoansTransactions;
}

const initialState: LoanTransactionState = {
  transactions: {},
} as LoanTransactionState;

export const loanTransactions = createSlice({
  name: 'loanTransactions',
  initialState: initialState,
  reducers: {
    FetchLoanTransactions(
      state: LoanTransactionState,
      action: PayloadAction<{ page: number; loanId: string }>
    ) {
      state.transactions[action.payload.loanId] = {
        transactions: {
          page: 0,
          moreRecords: false,
          results: [],
        },
        isLoading: false,
      };
      state.transactions[action.payload.loanId].isLoading = true;
    },
    SetLoanTransactions(
      state: LoanTransactionState,
      action: PayloadAction<{ loanId: string; data: PaginatedResponse<Transactions> }>
    ) {
      const results = action.payload.data.results.map(transaction => {
        return {
          loanId: transaction.loanId,
          bookingDate: transaction.bookingDate,
          amount: Math.abs(transaction.amount),
        };
      });

      action.payload.data.results = results;
      state.transactions[action.payload.loanId].transactions = action.payload.data;
      state.transactions[action.payload.loanId].isLoading = false;
    },
  },
});

export const transactionsActions = {
  ...loanTransactions.actions,
};

export const transactionSelector = (state: State) =>
  state[loanTransactions.name] as LoanTransactionState;

function* fetchLoanTransactions() {
  const accountService = container.resolve(AccountsService);
  const _t = getT();

  yield takeEvery(transactionsActions.FetchLoanTransactions, function* (action) {
    try {
      const transactions: PaginatedResponse<Transactions> = yield accountService.fetchTransactions(
        action.payload.loanId,
        action.payload.page
      );
      yield put(
        transactionsActions.SetLoanTransactions({
          loanId: action.payload.loanId,
          data: transactions,
        })
      );
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'loan transactions' })));
    }
  });
}

export function* loanTransactionsSaga() {
  yield fork(fetchLoanTransactions);
}
