import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Application,
  ApplicationAsset,
  ApplicationAssetsResponse,
  ApplicationStatus,
  PaginatedResponse,
} from 'models/Application';
import { Loan } from 'models/Loan';
import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { container } from 'tsyringe';
import { State } from 'utils/store';
import { notifyError, notifySuccess } from 'modules/notification/store';
import { getT } from 'utils/framework/intl';
import { Lead, LeadsResponse, LeadStage } from 'models/Lead';
import { CrmService } from 'services/CrmService';
import { AccountsService } from 'services/AccountsService';
import { auth0Selector, Auth0State } from 'modules/auth/authStore';
import { UserType } from 'models/User';
import { captureException } from 'utils/reporting';
import { ALLOWED_LEAD_STAGES } from 'utils/constants';

export interface DashboardState {
  loanList: PaginatedResponse<Loan>;
  loanAssetsLoadingList: string[];
  loanListIsLoading: boolean;
  leads: {
    page: number;
    moreRecords: boolean;
    results: Lead[];
  };
  leadsAreLoading: boolean;
  applicationList: PaginatedResponse<Application>;
  applicationListActiveStatuses?: string[];
  applicationListStatusType?: ApplicationStatus;
  applicationListIsLoading: boolean;
  areApplicationFiltersApplied: boolean;
  loanFilters: string[];
}

const initialState = {
  loanAssetsLoadingList: [],
  loanList: {
    page: 0,
    moreRecords: false,
    results: [],
  },
  loanListIsLoading: false,
  leads: {
    page: 1,
    moreRecords: false,
    results: [],
  },
  leadsAreLoading: true,
  applicationList: {
    page: 1,
    moreRecords: false,
    results: [],
  },
  applicationListStatusType: ApplicationStatus.ALL,
  applicationListIsLoading: false,
  areApplicationFiltersApplied: false,
  loanFilters: [],
} as DashboardState;

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    FetchLoans(
      state: DashboardState,
      action: PayloadAction<{ page: number; statuses?: string[] }>
    ) {
      const statuses = action.payload.statuses;
      state.loanFilters = statuses || [];
      state.loanListIsLoading = true;
    },
    FetchLoan(state: DashboardState, action: PayloadAction<{ id: string }>) {},
    FetchLoansComplete(
      state: DashboardState,
      action: PayloadAction<{ data: PaginatedResponse<Loan> }>
    ) {
      state.loanList = action.payload.data;
      state.loanListIsLoading = false;

      action.payload.data.results.forEach(({ loanId }) => {
        state.loanAssetsLoadingList.push(loanId);
      });
    },
    FetchLoanAssetsComplete(
      state: DashboardState,
      action: PayloadAction<{ assets: ApplicationAsset[]; loanId: string; term: number }>
    ) {
      const loan = state.loanList.results.find(loan => loan.loanId === action.payload.loanId);
      if (loan) {
        loan.assets = action.payload.assets;
        loan.term = action.payload.term;
      }
    },
    FetchLeads(state: DashboardState, action: PayloadAction<{ page: number }>) {
      state.leadsAreLoading = true;
    },
    FetchLeadsComplete(
      state: DashboardState,
      action: PayloadAction<{ data: PaginatedResponse<Lead> }>
    ) {
      state.leads.results = action.payload.data.results;
      state.leads.moreRecords = action.payload.data.moreRecords;
      state.leads.page = action.payload.data.page;
      state.leadsAreLoading = false;
    },
    cancelLead(state: DashboardState, action: PayloadAction<{ id: string }>) {},
    FetchApplicationAssets(
      state: DashboardState,
      action: PayloadAction<{ applicationId: string }>
    ) {},
    SetApplicationAssets(
      state: DashboardState,
      action: PayloadAction<{ assets: ApplicationAsset[]; applicationId: string }>
    ) {
      const selectedApplication = state.applicationList.results.find(
        application => application.id === action.payload.applicationId
      );
      if (selectedApplication) {
        selectedApplication.assets = action.payload.assets;
      }
    },
    FetchApplications(
      state: DashboardState,
      action: PayloadAction<{
        page?: number;
        statuses?: { arePassed: boolean; data: string[] };
        perPage?: number;
      }>
    ) {
      const statuses = action.payload.statuses;
      state.applicationListIsLoading = true;
      state.areApplicationFiltersApplied = !!statuses?.data && statuses.data.length > 0;
      if (statuses?.arePassed) {
        state.applicationListActiveStatuses = statuses.data;
      }
    },
    FetchApplicationsComplete(
      state: DashboardState,
      action: PayloadAction<{ data: PaginatedResponse<Application> }>
    ) {
      state.applicationList = action.payload.data;
      state.applicationListIsLoading = false;
    },
  },
});

export const dashboardActions = {
  ...dashboard.actions,
};
export const dashboardSelector = (state: State) => state[dashboard.name] as DashboardState;

function* fetchLoans() {
  const accountService = container.resolve(AccountsService);
  const _t = getT();

  yield takeEvery(dashboardActions.FetchLoans, function* ({ payload }) {
    try {
      const { loanFilters }: DashboardState = yield select(dashboardSelector);
      const data: PaginatedResponse<Loan> = yield accountService.fetchLoansList(
        payload.page,
        loanFilters
      );
      yield put(dashboardActions.FetchLoansComplete({ data }));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'loans' })));
    }
  });
}

function* fetchLeads() {
  const crmService = container.resolve(CrmService);
  const _t = getT();

  yield takeEvery(dashboardActions.FetchLeads, function* ({ payload }) {
    const authState: Auth0State = yield select(auth0Selector);
    const params =
      authState.user?.userType === UserType.Borrower
        ? {
            email: authState.user?.email,
            states: ALLOWED_LEAD_STAGES.join(','),
            page: payload.page,
          }
        : {
            partner_id: authState.user?.zohoId,
            states: ALLOWED_LEAD_STAGES.join(','),
            page: payload.page,
          };

    try {
      const data: LeadsResponse = yield crmService.fetchLeads(params);
      yield put(
        dashboardActions.FetchLeadsComplete({
          data: {
            results: data.data,
            moreRecords: data.info.moreRecords,
            page: data.info.page,
          },
        })
      );
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'leads' })));
    }
  });

  yield takeEvery(dashboardActions.cancelLead, function* ({ payload }) {
    const { leads }: DashboardState = yield select(dashboardSelector);
    try {
      yield crmService.updateLead(payload.id, {
        leadStage: LeadStage.Rejected,
        rejectionReason: 'Not Taken Up',
      });

      let newPage = leads.page;
      if (!leads.moreRecords && leads.page !== 1 && leads.results.length <= 1) {
        newPage = leads.page - 1;
      }

      yield put(dashboardActions.FetchLeads({ page: newPage }));
      yield put(notifySuccess(_t('notification.deleteLeadSuccess')));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.deleteLeadFailed')));
    }
  });
}

function* fetchApplications() {
  const crmService = container.resolve(CrmService);
  const _t = getT();

  yield takeEvery(dashboardActions.FetchApplicationAssets, function* (action) {
    try {
      const data: ApplicationAssetsResponse = yield crmService.fetchApplicationAssets(
        action.payload.applicationId
      );
      yield put(
        dashboardActions.SetApplicationAssets({
          assets: data.data,
          applicationId: action.payload.applicationId,
        })
      );
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'application assets' })));
    }
  });

  yield takeEvery(dashboardActions.FetchApplications, function* (action) {
    try {
      const { applicationListActiveStatuses }: DashboardState = yield select(dashboardSelector);
      const currentPage = action.payload.page ? action.payload.page : 1;

      const data: PaginatedResponse<Application> = yield crmService.fetchApplicationList({
        page: currentPage,
        statuses: applicationListActiveStatuses,
        perPage: action.payload.perPage,
      });
      yield put(dashboardActions.FetchApplicationsComplete({ data }));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'applications' })));
    }
  });
}

function* forkNApplicationAssets() {
  yield takeEvery(dashboardActions.FetchLoansComplete, function* (action) {
    for (const loan of action.payload.data.results) {
      yield fork(fetchApplicationAssets, loan.loanId);
    }
  });
}

function* fetchApplicationAssets(loanId: string) {
  const crmService = container.resolve(CrmService);

  try {
    const applicationData: PaginatedResponse<Application> = yield crmService.fetchApplicationList({
      page: 1,
      statuses: [],
      perPage: 1,
      loanId: loanId,
    });
    if (applicationData.results.length !== 1) {
      throw new Error(
        `Application endpoint returned ${applicationData.results.length} application associated with a loan`
      );
    }
    const assets: ApplicationAssetsResponse = yield crmService.fetchApplicationAssets(
      applicationData.results[0].id
    );
    yield put(
      dashboardActions.FetchLoanAssetsComplete({
        assets: assets.data,
        loanId,
        term: applicationData.results[0].term,
      })
    );
  } catch (err) {
    captureException(err);
    return;
  }
}

export function* dashboardSaga() {
  yield fork(fetchLoans);
  yield fork(fetchLeads);
  yield fork(fetchApplications);
  yield fork(forkNApplicationAssets);
}
