import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { userTypeSelector } from 'modules/auth/authStore';
import { formatDate } from 'utils/date';
import { Currency, formatCurrency, translateLabels } from 'utils/string';
import { transactionSelector, transactionsActions, LoansTransactions } from './store';
import { Transactions } from 'models/Loan';

@singleton()
@autobind
export class LoanTransactionsViewModel extends ViewModel {
  state!: {
    transactions: LoansTransactions;
    tableHead: string[];
  };

  protected tableHead = ['loans.bookingDate', 'loans.credit'];

  getCurrentTransactions = (loanId: string) => {
    return this.state.transactions[loanId];
  };

  getTableBody = (loanId: string, currency: Currency) => {
    return formatTransactionsToTableBody(
      this.getCurrentTransactions(loanId)?.transactions?.results || [],
      currency
    );
  };

  getIsLoading = (loanId: string) => {
    return this.getCurrentTransactions(loanId)?.isLoading;
  };

  getTransactions = (loanId: string) => {
    this.store.dispatch(transactionsActions.FetchLoanTransactions({ page: 0, loanId: loanId }));
  };

  nextTransactionPage = (loanId: string) => {
    this.store.dispatch(
      transactionsActions.FetchLoanTransactions({
        page: this.getCurrentTransactions(loanId).transactions.page + 1,
        loanId: loanId,
      })
    );
  };

  previousTransactionPage = (loanId: string) => {
    this.store.dispatch(
      transactionsActions.FetchLoanTransactions({
        page: this.getCurrentTransactions(loanId).transactions.page - 1,
        loanId: loanId,
      })
    );
  };

  hasTransactionsNextPage = (loanId: string) => {
    return this.getCurrentTransactions(loanId)?.transactions.moreRecords;
  };

  getCurrentPage = (loanId: string) => {
    return this.getCurrentTransactions(loanId)?.transactions.page;
  };

  protected stateMapper = createSelector(
    transactionSelector,
    userTypeSelector,
    transactionState => {
      return {
        transactions: transactionState.transactions,
        tableHead: translateLabels(this.tableHead),
      };
    }
  );
}

const formatTransactionsToTableBody = (transactions: Transactions[], currency: Currency) => {
  return transactions.map(transaction => {
    return [formatDate(transaction.bookingDate), formatCurrency(transaction.amount, currency)];
  });
};
