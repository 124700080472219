import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { uiActions, uiSelector } from 'modules/ui-components/store';
import { hasPermission, Permission } from 'modules/auth/services/PermissionsService';
import { _t } from 'utils/string';
import {
  auth0Selector,
  isBorrowerSelector,
  isBrokerSelector,
  isDealerSelector,
} from 'modules/auth/authStore';
import { ProfileData, User } from 'models/User';
import { leadActions } from 'modules/leads/store';
import { NEW_LEAD } from 'utils/constants';
import { profileActions, profileSelector } from 'modules/profile/store';

@singleton()
@autobind
export class LoanCalculatorViewModel extends ViewModel {
  state!: {
    calculatorOpen?: boolean;
    user?: User;
    isBroker: boolean;
    isDealer: boolean;
    isBorrower: boolean;
    isLogoutDisabled: boolean;
    profileData: ProfileData;
    profileRetrievalFailed: boolean;
    isProfileLoading: boolean;
    isUnverified: boolean;
  };

  protected connected() {
    const canRetrieveProfileData = this.state.user?.zohoId && this.state.user?.isVerified;

    if (canRetrieveProfileData) {
      if (this.state.isBorrower)
        this.store.dispatch(
          profileActions.FetchCustomerProfile({ customerId: this.state.user?.zohoId as string })
        );
      else
        this.store.dispatch(
          profileActions.FetchPartnerProfile({ partnerId: this.state.user?.zohoId as string })
        );
    }
  }

  toggleCalculator() {
    if (this.state.isBroker) {
      this.store.dispatch(leadActions.RedirectToLeadSteps(NEW_LEAD));
      return;
    }

    this.store.dispatch(uiActions.ToggleCalculatorAction());
  }

  toggleDrawer() {
    this.store.dispatch(uiActions.ToggleDrawer());
  }

  get getCalcBtnText() {
    return this.state.calculatorOpen ? 'loans.close' : 'loans.show';
  }

  get getPartnerTitleText() {
    if (this.state.isProfileLoading) return '';

    if (this.state.profileData) {
      return this.formatCompanyName(this.state.profileData.name, this.state.profileData.referenceNumber)
    } else if (this.state.profileRetrievalFailed && this.state.user?.name) {
      return this.state.user.name;
    }
    return this.state.user?.email;
  }

  get getBorrowerTitleText() {
    if (this.state.isProfileLoading) return '';

    if (this.state.profileData) {
      return this.formatCompanyName(this.state.profileData.name, this.state.profileData.referenceNumber)
    }

    return this.state.user?.email;
  }

  formatCompanyName(companyName: string, referenceNumber: string | null) {
    if (referenceNumber) 
      return `${companyName} (${referenceNumber})`;
    return companyName
  }

  get getTitleText() {
    if (this.state.calculatorOpen) {
      return _t('loans.financeCalculator');
    }

    if (this.state.isBroker || this.state.isDealer) {
      return this.getPartnerTitleText;
    }

    return this.getBorrowerTitleText;
  }

  canCreateApplication({ unverifiedUsersCanApply }: { unverifiedUsersCanApply: boolean }) {
    if (this.state.isUnverified && !unverifiedUsersCanApply) return false;

    return hasPermission(Permission.CanCreateApplication, this.state.user?.permissions);
  }

  protected stateMapper = createSelector(
    profileSelector,
    auth0Selector,
    uiSelector,
    isBrokerSelector,
    isDealerSelector,
    isBorrowerSelector,
    (profile, auth, ui, isBroker, isDealer, isBorrower) => ({
      isBroker,
      isDealer,
      isBorrower,
      calculatorOpen: ui.calculator.open,
      isUnverified: !auth.user?.isVerified,
      user: auth.user,
      isLogoutDisabled: auth.isLoggingOut,
      profileData: profile.profile,
      profileRetrievalFailed: profile.profileRetrievalFailed,
      isProfileLoading: profile.isProfileLoading,
    })
  );
}
