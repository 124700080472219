import autobind from 'autobind-decorator';
import {
  Loan,
  LoansResponse,
  CamelisedLoansResponse,
  PaginatedResponse,
  LoanTransactionResponse,
  Transactions,
} from 'models/Loan';
import { singleton } from 'tsyringe';
import { cameliseKeys } from '../utils/string';
import { Http } from './Http';

@singleton()
@autobind
export class AccountsService {
  protected readonly http: Http;

  constructor(apiUrl: string = '/accounts') {
    this.http = new Http(apiUrl);
  }

  fetchLoansList = async (
    page: number,
    loanStatuses: string[]
  ): Promise<PaginatedResponse<Loan>> => {
    const pageLimit = 10;
    const offset = pageLimit * page;
    let url = `/v1/loans?offset=${offset}&limit=${pageLimit}`;
    for (let status of loanStatuses) {
      url = url.concat(`&state=${status}`);
    }
    const { data }: LoansResponse = await this.http.request(url);

    const camelisedData: CamelisedLoansResponse = cameliseKeys(data);
    return {
      results: camelisedData.results,
      page: page,
      moreRecords: camelisedData.hasMore,
    };
  };

  fetchTransactions = async (
    loanId: string,
    page: number
  ): Promise<PaginatedResponse<Transactions>> => {
    const pageLimit = 10;
    const offset = pageLimit * page;

    const { data }: LoanTransactionResponse = await this.http.request(
      `/v1/loans/${loanId}/transactions?transaction_type=CREDIT&offset=${offset}&limit=${pageLimit}`
    );
    const camelisedData = cameliseKeys(data);

    return {
      results: camelisedData.results,
      page: page,
      moreRecords: camelisedData.hasMore,
    };
  };
}
