import autobind from 'autobind-decorator';
import {
  Application,
  ApplicationAssetsResponse,
  ApplicationsResponse,
  PaginatedResponse,
} from 'models/Application';
import { FetchLeadsParams, Lead, LeadsResponse } from 'models/Lead';
import { OpenBankingStatusList } from 'models/openBanking';
import { Customer, Partner } from 'models/UserType';
import { singleton } from 'tsyringe';
import { cameliseKeys, camelKeysToSnakeCase } from '../utils/string';
import { Http } from './Http';

interface ApplicationListPayload {
  page: number;
  statuses?: string[];
  perPage?: number;
  loanId?: string;
}

interface ApplicationListParams {
  page: number;
  per_page: number;
  states?: string;
  loan_id?: string;
}

@singleton()
@autobind
export class CrmService {
  protected readonly http: Http;

  constructor(apiUrl: string = '/crm') {
    this.http = new Http(apiUrl);
  }

  resendVerificationEmail = async (userId: string) => {
    await this.http.request('/users/email-verification', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: camelKeysToSnakeCase({ userId }),
    });
  };

  fetchApplication = async (id: string): Promise<Application> => {
    const { data } = await this.http.request(`/applications/${id}`);
    return cameliseKeys(data);
  };

  fetchApplicationAssets = async (id: string): Promise<ApplicationAssetsResponse> => {
    const { data } = await this.http.request(`/applications/${id}/assets`);
    return cameliseKeys(data);
  };

  fetchApplicationList = async ({
    page,
    statuses,
    perPage,
    loanId,
  }: ApplicationListPayload): Promise<PaginatedResponse<Application>> => {
    const params: ApplicationListParams = {
      page: page,
      per_page: perPage ? perPage : 10,
      loan_id: loanId,
    };

    if (statuses?.length) {
      params['states'] = statuses.join(',');
    }

    const { data } = await this.http.request(`/applications`, { params });
    const camelisedData: ApplicationsResponse = cameliseKeys(data);
    return {
      results: camelisedData.data,
      page: camelisedData.info.page,
      moreRecords: camelisedData.info.moreRecords,
    };
  };

  updateApplicationOpenBankingStatus = async (
    id: string,
    application: { openBankingState: OpenBankingStatusList }
  ) => {
    const { data } = await this.http.request(`/applications/${id}`, {
      method: 'PUT',
      params: { trigger: 'workflow,blueprint' },
      headers: { 'Content-Type': 'application/json' },
      data: camelKeysToSnakeCase(application),
    });
    return cameliseKeys(data);
  };

  fetchLeads = async (params: FetchLeadsParams): Promise<LeadsResponse> => {
    const { data } = await this.http.request('/leads', { params: { ...params, per_page: 10 } });
    const camelisedData: LeadsResponse = cameliseKeys(data);
    return camelisedData;
  };

  fetchLead = async (id: string): Promise<Lead> => {
    const { data } = await this.http.request(`/leads/${id}`);
    return cameliseKeys(data);
  };

  createLead = async (lead: Partial<Lead>): Promise<Lead> => {
    const { data } = await this.http.request('/leads', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: camelKeysToSnakeCase(lead),
    });

    return cameliseKeys(data);
  };

  updateLead = async (id: string, lead: Partial<Lead>) => {
    const { data } = await this.http.request(`/leads/${id}`, {
      method: 'PUT',
      params: { trigger: 'workflow,blueprint' },
      headers: { 'Content-Type': 'application/json' },
      data: camelKeysToSnakeCase(lead),
    });
    return cameliseKeys(data);
  };

  fetchCustomer = async (id: string): Promise<Customer> => {
    const { data } = await this.http.request(`/customers/${id}`);
    return cameliseKeys(data);
  };

  fetchPartner = async (id: string): Promise<Partner> => {
    const { data } = await this.http.request(`/partners/${id}`);
    return cameliseKeys(data);
  };
}
