import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { dashboardActions, dashboardSelector } from 'modules/dashboard/store';
import { Loan, PaginatedResponse } from 'models/Loan';
import { auth0Selector, isBorrowerSelector } from 'modules/auth/authStore';
import { Option } from 'modules/ui-components/MultiSelect';

@singleton()
@autobind
export class LoansViewModel extends ViewModel {
  unsubscribe!: () => void;
  state!: {
    zohoId: string;
    loanList: PaginatedResponse<Loan>;
    loanListPage: number;
    loanListHasNextPage: boolean;
    loanListIsLoading: boolean;
    loanFiltersApplied: boolean;
    isBorrower: boolean;
  };

  previousLoansPage = () => {
    const { loanListPage } = this.state;
    this.store.dispatch(dashboardActions.FetchLoans({ page: loanListPage - 1 }));
  };

  nextLoansPage = () => {
    const { loanListPage } = this.state;
    this.store.dispatch(dashboardActions.FetchLoans({ page: loanListPage + 1 }));
  };

  protected connected() {
    if (this.state.zohoId) {
      this.store.dispatch(dashboardActions.FetchLoans({ page: 0 }));
    }
  }

  protected stateMapper = createSelector(
    dashboardSelector,
    auth0Selector,
    isBorrowerSelector,
    (dashboard, auth, isBorrower) => ({
      zohoId: auth.user?.zohoId,
      loanList: dashboard.loanList,
      loanListPage: dashboard.loanList.page,
      loanListHasNextPage: dashboard.loanList.moreRecords,
      loanListIsLoading: dashboard.loanListIsLoading,
      loanFiltersApplied: dashboard.loanFilters.length > 0,
      isBorrower,
    })
  );

  applyLoansFilters = (options: Array<Option>) => {
    let statuses = options.map(option => option.value);
    if (statuses.includes('Closed')) {
      statuses.push(...loansStatusClosedMapping);
    }
    this.store.dispatch(
      dashboardActions.FetchLoans({
        page: 0,
        statuses,
      })
    );
  };
}

export const loansStatusOptions = [
  {
    name: 'Active',
    value: 'Active',
  },
  {
    name: 'Closed',
    value: 'Closed',
  },
  {
    name: 'Secondary Rental',
    value: 'SEC',
  },
];

export const loansStatusClosedMapping = [
  'Settled',
  'New',
  'Frozen',
  'Cancelled',
  'Bad Debt Write off',
];
