import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import { getT } from 'utils/framework/intl';
import { HPProducts, LeaseProducts, Loan as LoanType } from 'models/Loan';
import { formatDate } from 'utils/date';
import { Arrow } from 'modules/ui-components/Arrow';
import { ApplicationAsset } from 'models/Application';
import { LoanTransactionsHistory } from './transactions/LoanTransactionHistory';
import { Currency, formatCurrency } from 'utils/string';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

interface Props {
  loan: LoanType;
  className?: string;
  isBorrower: boolean;
}

const LoanComponent = ({ className, loan, isBorrower }: Props) => {
  const _t = getT();
  const [isTransactionHistoryOpen, setsTransactionHistoryOpen] = useState(false);
  const [isLaptop] = useBreakpoint(Breakpoints.laptop, { useBody: true });
  const loanStatesMap = ['Active', 'Secondary Rental'];
  const isLease = LeaseProducts.includes(loan.loanName);
  const isHP = HPProducts.includes(loan.loanName);

  const currency: Currency = Currency[loan.currency as keyof typeof Currency];

  let sections = [
    [
      {
        label: _t('loans.financeAmount'),
        value: loan.loanAmount
          ? formatCurrency(loan.loanAmount, currency)
          : _t('loans.notAvailable'),
      },
      {
        label: _t('loans.nextPaymentAmount'),
        value: loan.nextPaymentAmount
          ? formatCurrency(loan.nextPaymentAmount, currency)
          : _t('loans.notAvailable'),
      },
    ],
    [
      {
        label: _t('loans.startDate'),
        value: loan.dateOpened ? formatDate(loan.dateOpened) : _t('loans.notAvailable'),
      },
      {
        label: 'Term',
        value: loan.term ? loan.term : _t('loans.notAvailable'),
      },
    ],
    [
      {
        label: 'Registration Number',
        value: loan.assets ? loan.assets[0].registrationNumber : _t('loans.notAvailable'),
      },
      {
        label: 'Serial Number',
        value: loan.assets ? loan.assets[0].chassisNumberVin : _t('loans.notAvailable'),
      },
    ],
  ];

  const isLeaseOrHP = isLease || isHP;

  const infoText = _t(isLease ? 'loans.futureVAT' : 'loans.futurePurchaseFee');

  return (
    <div className={className}>
      <div className="top">
        {isLaptop && isBorrower && (
          <div className="openDetails">
            <div
              className="openDetails--arrow"
              onClick={() => setsTransactionHistoryOpen(!isTransactionHistoryOpen)}
            >
              <Arrow open={isTransactionHistoryOpen} isLaptop={isLaptop} />
            </div>
          </div>
        )}
        <div className="informations">
          <div className="header">
            <div className="header-title-container">
              <p className="label">
                {loan.loanId}
                &nbsp;&nbsp;
                {loan.assets ? buildLoanDescription(loan.assets[0]) : ''}
              </p>
              <p className="header-title tooltip value">
                {loan.loanName ? loan.loanName : _t('loans.notAvailable')}
              </p>
            </div>

            <div className="header-status">
              <p className="value">{!loanStatesMap.includes(loan.state) ? 'Closed' : loan.state}</p>
              <p className="label small">{_t('loans.status')}</p>
            </div>
          </div>
          {sections.map((section, i) => (
            <div key={i} className="section">
              {section.map(({ label, value }) => (
                <div className="labeled-value" key={label}>
                  <div className="value">{value || _t('loans.notAvailable')}</div>
                  <p className="label">{label}</p>
                </div>
              ))}
            </div>
          ))}
          <div className="section">
            {loan.state !== 'Closed' && (
              <>
                <div className="labeled-value" key={_t('loans.currentBalance')}>
                  <span className="value">
                    {loan.balance
                      ? formatCurrency(loan.balance, currency)
                      : _t('loans.notAvailable')}{' '}
                  </span>
                  {isLeaseOrHP && loan.balance && (
                    <>
                      <span className="icon">
                        <InfoIcon />
                      </span>
                      <span className="info-tooltip">{infoText}</span>
                    </>
                  )}
                  <p className="label">{_t('loans.currentBalance')}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isBorrower && isTransactionHistoryOpen && (
        <LoanTransactionsHistory loanId={loan.loanId} currency={currency} />
      )}

      {!isLaptop && isBorrower && (
        <div
          className="openDetails--mobile"
          onClick={() => setsTransactionHistoryOpen(!isTransactionHistoryOpen)}
        >
          <Arrow open={isTransactionHistoryOpen} isLaptop={isLaptop} />
        </div>
      )}
    </div>
  );
};

const buildLoanDescription = (assets: ApplicationAsset) => {
  const description =
    (assets.make || '') + ' ' + (assets.model || '') + ' ' + (assets.yearOfManufacture || '');
  return description;
};

export const Loan = styled(LoanComponent)`
  margin-bottom: 1rem;
  border: var(--border-default);
  border-radius: var(--border-radius);

  .top {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    @media (max-width: ${Breakpoints.laptop}px) {
      flex-direction: column;
    }

    .openDetails {
      min-width: 2rem;
      padding: 0.5rem;
      &--arrow {
        margin-top: 2.375rem;
        cursor: pointer;
      }
    }

    .informations {
      flex: 1;
      gap: 1rem;
      display: grid;
      grid-template-areas: 'header header header header';
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

      @media (max-width: ${Breakpoints.laptop}px) {
        grid-template-areas: 'header';
        grid-template-columns: 1fr;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        grid-area: header;
        padding-bottom: 0.375rem;
        border-bottom: 1px solid var(--primary-dark-blue-50);

        .label {
          font-size: 1rem;
          line-height: 1.67;
          color: var(--primary-dark-300);
          margin: 0 0 0.5rem;
        }
        &-title-container {
          flex: 3;
        }

        &-title {
          color: var(--primary-dark-blue-500);
        }

        &-status {
          flex: 1;
          margin-left: 1rem;
          .label {
            margin: 0;
          }
          .small {
            font-size: 0.75rem;
          }

          @media (max-width: ${Breakpoints.laptop}px) {
            flex: 2;
            text-align: right;
          }
        }
      }

      .section {
        border-right: 1px solid var(--primary-dark-blue-50);
        padding-right: 1rem;

        @media (max-width: ${Breakpoints.laptop}px) {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          padding-right: 0;
          border-right: none;

          .labeled-value:nth-of-type(2n) {
            text-align: right;
          }
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .openDetails--mobile {
    display: grid;
    place-items: center;
    padding: 1rem;
    border-top: 1px solid var(--primary-dark-blue-50);
    cursor: pointer;
  }

  .tooltip {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }

  .label {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
    margin: 0 0 0.5rem 0;
  }

  .value {
    font-family: var(--font-family-secondary);
    color: var(--primary-blue-500);
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 0;

    &--small {
      margin-top: 0.375rem;
      color: var(--primary-blue-500);
      font-size: 0.75rem;
      font-weight: 600;
      font-family: var(--font-family-secondary);
      line-height: 1.67;
      letter-spacing: normal;
    }
  }
  .icon {
    cursor: pointer;
    margin-left: 0.125rem;

    svg {
      height: 1.12rem;
    }

    &:hover + .info-tooltip {
      display: block;
      opacity: 1;
    }
  }

  .info-tooltip {
    font-size: 0.75rem;

    text-align: center;
    padding: 0.5rem;
    margin-bottom: 0.375rem;
    width: fit-content;

    background-color: var(--primary-dark-000);
    color: var(--primary-dark-blue-900);

    border: var(--border-default);
    border-radius: var(--border-radius);
    border-color: var(--primary-dark-blue-300);

    display: none;
    opacity: 0;
    transition: visibility 0.1s linear, opacity 0.1s linear;
  }
`;
