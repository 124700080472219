import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { EXCLUDED_APPLICATION_STATES } from 'utils/constants';
import { dashboardActions, dashboardSelector } from 'modules/dashboard/store';
import { Application, ApplicationStatus, PaginatedResponse } from 'models/Application';
import { auth0Selector, isBorrowerSelector } from 'modules/auth/authStore';
import { _t } from 'utils/string';
import { Module, uploadDocumentsActions } from 'modules/upload-documents/store';
import { Option as MultiSelectOption } from 'modules/ui-components/MultiSelect';

export interface ApplicationStatusOption {
  key: ApplicationStatus;
  label: string;
  selected: boolean;
}

@singleton()
@autobind
export class ApplicationsViewModel extends ViewModel {
  unsubscribe!: () => void;
  state!: {
    zohoId: string;
    applicationList: PaginatedResponse<Application>;
    applicationListPage: number;
    applicationListHasNextPage: boolean;
    applicationListIsLoading: boolean;
    applicationStatusOptions: ApplicationStatusOption[];
    areApplicationFiltersApplied: boolean;
    isBorrower: boolean;
  };

  protected applicationStatusOptions = getApplicationStatusOptions();

  get perPage() {
    return this.state.isBorrower ? 3 : 10;
  }

  getApplicationAssets = (applicationId: string) => {
    this.store.dispatch(dashboardActions.FetchApplicationAssets({ applicationId }));
  };

  previousApplicationsPage = () => {
    const { applicationListPage } = this.state;
    this.store.dispatch(
      dashboardActions.FetchApplications({ page: applicationListPage - 1, perPage: this.perPage })
    );
  };

  nextApplicationsPage = () => {
    const { applicationListPage } = this.state;
    this.store.dispatch(
      dashboardActions.FetchApplications({ page: applicationListPage + 1, perPage: this.perPage })
    );
  };

  applyApplicationFilters = (options: Array<MultiSelectOption>) => {
    const statuses = options.map(option => option.value);
    this.store.dispatch(
      dashboardActions.FetchApplications({
        page: 1,
        statuses: { arePassed: true, data: statuses },
      })
    );
  };

  uploadDocuments = (id: string) => {
    this.store.dispatch(
      uploadDocumentsActions.RedirectToUploadDocuments({ id: id, module: Module.APPLICATION })
    );
  };

  protected connected() {
    if (this.state.zohoId) {
      this.store.dispatch(dashboardActions.FetchApplications({ page: 1, perPage: this.perPage }));
    }
  }

  protected stateMapper = createSelector(
    dashboardSelector,
    auth0Selector,
    isBorrowerSelector,
    (dashboard, auth, isBorrower) => ({
      isBorrower,
      zohoId: auth.user?.zohoId,
      applicationList: {
        ...dashboard.applicationList,
        results: dashboard.applicationList.results.filter(
          (application: Application) => !EXCLUDED_APPLICATION_STATES.includes(application.state)
        ),
      },
      applicationListPage: dashboard.applicationList.page,
      applicationListHasNextPage: dashboard.applicationList.moreRecords,
      applicationListIsLoading: dashboard.applicationListIsLoading,
      applicationStatusOptions: this.applicationStatusOptions.map(o => ({
        ...o,
        selected: o.key === dashboard.applicationListStatusType,
      })),
      areApplicationFiltersApplied: dashboard.areApplicationFiltersApplied,
    })
  );
}

const getApplicationStatusOptions = () => [
  { key: ApplicationStatus.ALL, label: _t('loans.all') },
  { key: ApplicationStatus.DRAFT, label: _t('loans.draft') },
  { key: ApplicationStatus.READY_FOR_PROPOSAL, label: _t('loans.readyForProposal') },
  { key: ApplicationStatus.PROPOSAL, label: _t('loans.proposal') },
  { key: ApplicationStatus.UNDERWRITING, label: _t('loans.underwriting') },
  {
    key: ApplicationStatus.NEED_MORE_INFORMATION,
    label: _t('loans.needMoreInformation'),
  },
  { key: ApplicationStatus.ACCEPTED, label: _t('loans.accepted') },
  { key: ApplicationStatus.DECLINED, label: _t('loans.declined') },
  { key: ApplicationStatus.PAY_OUT, label: _t('loans.payOut') },
  { key: ApplicationStatus.DOCUMENTS_ISSUED, label: _t('loans.documentsIssued') },
  { key: ApplicationStatus.DOCUMENTS_RECEIVED, label: _t('loans.documentsReceived') },
];

export const applicationStatusOptions = [
  {
    name: 'Application Submitted',
    value: 'Proposal',
  },
  {
    name: 'Awaiting Decision',
    value: 'Underwriting - To Be Assigned,Underwriting - In Review',
  },
  {
    name: 'More Information Required',
    value: 'Need More Information',
  },
  {
    name: 'Application Accepted',
    value: 'Accepted,Begin Fulfilment',
  },
  {
    name: 'Invoice Requested',
    value: 'Invoice Requested',
  },
  {
    name: 'Credit Agreement Sent',
    value: 'Documents Issued',
  },
  {
    name: 'Finalising Application',
    value:
      'Documents Received,Ready For QC1 Check,Begin QC1 Check,Ready for QC2 Check,Begin QC2 Check,Ready For Payout,Pay Out',
  },
  {
    name: 'Application Activated',
    value: 'Activated',
  },
  {
    name: 'Application Declined',
    value: 'Declined',
  },
  {
    name: 'Application Cancelled',
    value: 'Cancelled',
  },
  {
    name: 'Application Not Taken Up',
    value: 'Not Taken Up',
  },
];
